import React, { useContext, useEffect, useRef } from 'react';
import ConsultButton from '../../Utilities/ConsultButton/ConsultButton';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

import Init from '../../../Init/Init';
import './Header.scss';

import { buildWaLink, legalServiceLink, packageLink } from '../../../Util/Util';

let config = Init.config;
let { imageFolder, headerFolder, carousel } = config;

const TemplateOne = ({ index, backgroundImage, mascot, header, text, ctaButton, affirmation, displayButtonOnMobileView = false }) => {
    return (
        <div id={`header-${index}`} className="header" style={{
            background: `url(${headerFolder}${backgroundImage})`,
            backgroundPosition: `center`,
            backgroundSize: `cover`
        }}>
            <div className={`header-content ${ index % 2 === 0 ? 'reversed' : '' }`}>
                <div className="header-logo">
                    <img src={imageFolder + mascot} alt="Landack. Logo" />
                </div>
                <div className="header-title-container">
                    <h1 className='header-title' dangerouslySetInnerHTML={{__html: header}}>
                        
                    </h1>
                    <div className='header-tagline'> 
                        <span dangerouslySetInnerHTML={{__html: text.tagline}}>
                        </span>
                        {
                            text.whisper && (
                                <small>({ text.whisper })</small>
                            )
                        }
                    </div>
                    <div className="header-cta-button-container">
                        {
                            ctaButton.map( (item, index) => {
                                const buttonClick = item.hasOwnProperty('callback') ? item.callback : null;

                                return (
                                    <ConsultButton 
                                        customClass={`header-cta-button ${ displayButtonOnMobileView ? '' : 'd-none' } `} 
                                        text={ item.text } 
                                        href={ item.url } 
                                        onClick={ buttonClick }
                                        key={ index }
                                    />
                                )
                            } )
                        }
                    </div>
                    <small className="notes" dangerouslySetInnerHTML={{__html: affirmation}}>
                    </small>
                </div>
            </div>
        </div>
    );
}

const TemplateTwo = ({ index, backgroundImage, mascot, header, text, ctaButton, affirmation }) => {
    const gaEventCategory = "Header Download Button";
    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    return (
        <div id={`header-${index}`} className="header-2" style={{
            background: `url(${headerFolder}custom-template-2/${backgroundImage})`,
            backgroundPosition: `center`,
            backgroundSize: `cover`
        }}>
            <div className={`header-content`}>
                <div className="header-logo first">
                    <img src={headerFolder + 'custom-template-2/' + mascot.first} alt="Landack. Logo" />
                </div>
                <div className="header-logo second">
                    <img src={headerFolder + 'custom-template-2/' + mascot.second} alt="Landack. Logo" />
                </div>
                <div className="header-title-container">
                    <h1 className='header-title' dangerouslySetInnerHTML={{__html: header}}>
                    </h1>
                    <div className="header-cta-container">
                        <div className="header-google-play-search">
                            <img src={ headerFolder + 'custom-template-2/' + mascot.third } alt="" />
                        </div>
                        <div className="header-google-play-button">
                            <img src={ headerFolder + 'custom-template-2/' + mascot.fourth } alt="" />
                        </div>
                        <div className="header-button-container">
                            <a 
                                className="header-download-button" 
                                href={ ctaButton.url } 
                                target='_blank'
                                onClick={ () => gaEventTracker('Click', 'Google Play Download') }
                                onMouseEnter={() => gaEventTracker('Hover', 'Google Play Download')}    
                            > { ctaButton.text } </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const TemplateThree = ({ index }) => {
    const gaEventCategory = "Header Business Products";
    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    return (
        <div id={`header-${index}`} className='header-3'>
            {/* 
                3 columns
                skewed
            */}
            <div className="header-skewed-column" id="starter-kit">
                <div className="column-wrapper">
                    <h3 className="column-title">
                        Mulai usaha dengan <span className='product-title'> Starter Kit! </span>
                    </h3>
                    <a 
                        href="/pendirian-badan-usaha" 
                        className="column-button"
                        target='_blank'
                        onClick={ () => gaEventTracker('Click', 'Starter Kit') }
                        onMouseEnter={() => gaEventTracker('Hover', 'Starter Kit')}    
                    >
                        Cek Sekarang
                    </a>
                    <div className="column-image">
                        <img src={ `${headerFolder}custom-template-3/starter-kit.svg` } alt="" />
                    </div>
                </div>
            </div>
            <div className="header-skewed-column" id="legal-boost">
                <div className="column-wrapper">
                    <h3 className="column-title">
                        Beresin usaha dengan <span className='product-title'> Legal Boost! </span>
                    </h3>
                    <a 
                        href="/landack-bisnis#legal-boost" 
                        className="column-button"
                        target='_blank'
                        onClick={ () => gaEventTracker('Click', 'Starter Kit') }
                        onMouseEnter={() => gaEventTracker('Hover', 'Starter Kit')}    
                    >
                        Cek Sekarang
                    </a>
                    <div className="column-image">
                        <img src={ `${headerFolder}custom-template-3/legal-boost.svg` } alt="" />
                    </div>
                </div>
            </div>
            <div className="header-skewed-column" id="membership">
                <div className="column-wrapper">
                    <h3 className="column-title">
                        Jaga usaha dengan <span className='product-title'> Membership! </span>
                    </h3>
                    <a 
                        href="/landack-bisnis#membership" 
                        className="column-button"
                        target='_blank'
                        onClick={ () => gaEventTracker('Click', 'Starter Kit') }
                        onMouseEnter={() => gaEventTracker('Hover', 'Starter Kit')}    
                    >
                        Cek Sekarang
                    </a>
                    <div className="column-image">
                        <img src={ `${headerFolder}custom-template-3/membership.svg` } alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

// Lawyer App
const TemplateFour = ({ index, backgroundImage, header, text, firstSection, secondSection, affirmation }) => {
    /**
     * Sections:
     * 1. Mascot
     * 2. Text
     * 3. Buttons
     */
    return (
        <div id={`header-${index}`} className="header-4" style={{
            background: `url(${headerFolder}${backgroundImage})`,
            backgroundPosition: `center`,
            backgroundSize: `cover`
        }}>
            <div className={`header-content`}>
                <div className="header-title-container">
                    <h1 className='header-title' dangerouslySetInnerHTML={{__html: header}}>
                        
                    </h1>
                    <div className='header-tagline'> 
                        <span dangerouslySetInnerHTML={{__html: text.tagline}}>
                        </span>
                        <small>({ text.whisper })</small>
                    </div>
                </div>

                <div className="header-section-container">
                    <div className="header-first-section">
                        <div className="header-section-text-container">
                            <h5 className="header-section-title">
                                { firstSection.text }
                            </h5>
                            <div className="header-cta-button-container">
                                {
                                    firstSection.ctaButton.map( (item, index) => {
                                        const buttonClick = item.hasOwnProperty('callback') ? item.callback : null;

                                        return (
                                            <ConsultButton 
                                                customClass={`header-cta-button`} 
                                                text={ item.text } 
                                                href={ item.url } 
                                                onClick={ buttonClick }
                                                key={ index }
                                            />
                                        )
                                    } )
                                }
                            </div>
                        </div>
                        <div className="header-section-image-container">
                            <img src={`${ firstSection.mascot }`} />
                        </div>
                    </div>
                    <div className="header-section-delimiter">
                        
                    </div>
                    <div className="header-second-section">
                        <div className="header-section-image-container">
                            <img src={`${ secondSection.mascot }`} />
                        </div>
                        <div className="header-section-text-container">
                            <h5 className="header-section-title">
                                { secondSection.text }
                            </h5>
                            <div className="header-cta-button-container">
                                {
                                    secondSection.ctaButton.map( (item, index) => {
                                        const buttonClick = item.hasOwnProperty('callback') ? item.callback : null;

                                        return (
                                            <ConsultButton 
                                                customClass={`header-cta-button`} 
                                                text={ item.text } 
                                                href={ item.url } 
                                                onClick={ buttonClick }
                                                key={ index }
                                            />
                                        )
                                    } )
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <small className="notes" dangerouslySetInnerHTML={{__html: affirmation}}>
                </small>
            </div>
        </div>
    );
}

const HeaderCarousel = (props) => {
    let slider = [
        {
            /**
             * Background Image
             * Mascot
             * Header
             * Text(Tagline, Whisper)
             * CTA Button
             * Affirmation: Working with PRP
             */
            backgroundImage: 'header-bg-blink.png',
            mascot: 'landack-b2b.png',
            // header: `<span class="highlighted">Paket UMKM</span> buat kamu yang berbisnis!`,
            header: `<span class="highlighted inline-mobile">Kepatuhan Hukum Itu Penting!</span> <span class="inline-mobile">Jadilah Bos yang Bijak Sekarang!</span>`,
            text: {
                tagline: `<span style="font-size: 19px; color: #000"><strong>Bisnis adalah perjalanan penuh keputusan. <br /> Apakah keputusanmu sudah paling bijak?</strong></span>`,
                // whisper: 'Daripada ujung-ujungnya kena masalah...'
            },
            ctaButton: [{
                text: 'Hubungi Landack.',
                url: buildWaLink("butuh", "layanan hukum"),
            }],
            displayButtonOnMobileView: true,
            affirmation: `Bekerja sama dengan <span class='underline'>Pribadi Randa & Partners Law Firm</span>`,
            templateType: 1
        }, // 1
        // {
        //     backgroundImage: 'header-bg-umkm.svg',
        //     mascot: 'corporate-pricing-umkm.svg',
        //     // header: `<span class="highlighted">Paket UMKM</span> buat kamu yang berbisnis!`,
        //     header: `<span class="highlighted inline-mobile">Solusi Hukum Satu Atap</span> <span class="inline-mobile">Melalui Lebih Paham Hukum</span>`,
        //     text: {
        //         tagline: `Mitigasi risiko hukum <br /> melalui pendalaman proses bisnis`,
        //         whisper: 'Daripada ujung-ujungnya kena masalah...'
        //     },
        //     ctaButton: [{
        //         text: 'Cek Sekarang',
        //         url: "#b2b-section",
        //         callback: () => {
        //             const section = document.getElementById("b2b-section");
        //             const navbar = document.getElementById("navigation-bar");
            
        //             window.scroll({
        //                 behavior: 'smooth',
        //                 top: section.offsetTop - navbar.clientHeight
        //             });
        //         }
        //     }],
        //     displayButtonOnMobileView: true,
        //     affirmation: `Bekerja sama dengan <span class='underline'>Pribadi Randa & Partners Law Firm</span>`,
        //     templateType: 1
        // }, // 1
        {
            templateType: 3,
        }, // 3
        {
            /**
             * Background Image
             * Mascot
             * Header
             * Text(Tagline, Whisper)
             * CTA Button
             * Affirmation: Working with PRP
             */
            backgroundImage: 'header-bg-cakrawala.png',
            header: `Kok ditahan-tahan? <br/> <span class="highlighted">Konsul hukum</span> sekarang!`,
            text: {
                tagline: 'Hanya Rp1.000 per menit!',
                whisper: 'Seriusan, coba aja konsul kalo ga percaya'
            },
            firstSection: {
                mascot: `${imageFolder}header-1.png`,
                text: `Mau mendapat konsultasi?`,
                ctaButton: [
                    {
                        text: 'Konsultasi di Android',
                        url: config.googlePlayLink
                    },
                    {
                        text: 'Konsultasi di iOS',
                        url: config.appStoreLink
                    },
                ],
            },
            secondSection: {
                mascot: `${headerFolder}custom-template-4/landack-lawyer.svg`,
                text: `Mau memberi konsultasi?`,
                ctaButton: [
                    {
                        text: 'Konsultan Hukum di Android',
                        url: config.consultantGooglePlayLink
                    },
                    {
                        text: 'Konsultan Hukum di iOS',
                        url: config.consultantAppStoreLink
                    },
                ],
                
            },
            affirmation: `Bekerja sama dengan <span class='underline'>Pribadi Randa & Partners Law Firm</span>`,
            templateType: 4,
        }, // 4
        {
            /**
             * Background Image
             * Mascot
             * Header
             * Text(Tagline, Whisper)
             * CTA Button
             * Affirmation: Working with PRP
             */
            backgroundImage: 'header-bg-blink.png',
            mascot: 'landack-contract-template.svg',
            header: `Butuh kontrak siap pakai untuk jaga usaha? <br/> Gunakan <span class="highlighted">Template Kontrak</span> sekarang!`,
            text: {
                tagline: 'Hanya Rp90.000 per template!',
                whisper: 'DOI aja bisa ingkar janji, ngapain ambil risiko'
            },
            ctaButton: [
                {
                    text: 'Beli via Android',
                    url: config.googlePlayLink
                },
                {
                    text: 'Beli via iOS',
                    url: config.appStoreLink
                }
            ],
            displayButtonOnMobileView: true,
            affirmation: `Dibuat & diverifikasi oleh <span class='underline'>Pribadi Randa & Partners Law Firm</span>`,
            templateType: 1
        }, // 1
    ]

    /**
     * Carousels
     */
    let carouselConfig = carousel;

    carouselConfig.containerClass = "header-container";
    carouselConfig.itemClass = "header-item-holder";
    carouselConfig.autoPlay = false;
    carouselConfig.autoPlaySpeed = 5000;
    carouselConfig.showDots = true;
    carouselConfig.infinite = true;
    carouselConfig.responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1200, min: 900 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobileTablet: {
            breakpoint: { max: 900, min: 650 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 650, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    }

   return (
        <Carousel
            swipeable={carouselConfig.swipeable}
            draggable={carouselConfig.draggable}
            showDots={carouselConfig.showDots}
            responsive={carouselConfig.responsive}
            ssr={carouselConfig.ssr} // means to render carousel on server-side.
            infinite={carouselConfig.infinite}
            autoPlay={carouselConfig.autoPlay}
            autoPlaySpeed={carouselConfig.autoPlaySpeed}
            keyBoardControl={carouselConfig.keyBoardControl}
            transitionDuration={carouselConfig.transitionDuration}
            removeArrowOnDeviceType={carouselConfig.removeArrowOnDeviceType}
            deviceType={carouselConfig.deviceType}
            containerClass={carouselConfig.containerClass}
            dotListClass={carouselConfig.dotListClass}
            itemClass={carouselConfig.itemClass}
            centerMode={carouselConfig.centerMode}
        >
            {
                slider.length > 0 ? (
                    slider.map((v, k) => {
                        switch(v.templateType) {
                            case 1:
                                return (
                                    <TemplateOne 
                                        key={k} 
                                        index={k} 
                                        backgroundImage={ v.backgroundImage }
                                        mascot={ v.mascot }
                                        header={ v.header }
                                        text={ v.text }
                                        ctaButton={ v.ctaButton }
                                        affirmation={ v.affirmation }
                                        displayButtonOnMobileView={ v.displayButtonOnMobileView }
                                    />
                                );
                            case 2:
                                return (
                                    <TemplateTwo 
                                        key={k} 
                                        index={k} 
                                        backgroundImage={ v.backgroundImage }
                                        mascot={ v.mascot }
                                        header={ v.header }
                                        ctaButton={ v.ctaButton }
                                        displayButtonOnMobileView={ v.displayButtonOnMobileView }
                                    />
                                );
                            case 3:
                                return (
                                    <TemplateThree key={k} index={k} />
                                );
                            case 4:
                                return (
                                    <TemplateFour 
                                        key={k}
                                        index={k}
                                        backgroundImage={ v.backgroundImage }
                                        header={ v.header }
                                        text={ v.text }
                                        firstSection={ v.firstSection }
                                        secondSection={ v.secondSection }
                                        affirmation={ v.affirmation }
                                    />
                                );
                            default:
                                return '';

                        }
                    })
                ) : (
                    <LoadingPage />
                )
            }
        </Carousel>
    );
}

const Header = (props) => {
    return <HeaderCarousel />
}



export default Header;