import React, {Component, Fragment, createRef, useEffect} from 'react';
import {Nav, Navbar} from 'react-bootstrap';
import './Navbar.scss';
import {Link, Routes, BrowserRouter as Router, Route, NavLink} from "react-router-dom";

import Init from '../../../Init/Init';

import { connect } from 'react-redux';
import metatagActions from '../../../Redux/MetaTags/metatag-action';
import legalServiceActions from '../../../Redux/LegalService/legalservice-action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { loadMetatag } = metatagActions;
const { loadLegalServices } = legalServiceActions;

const withParameters = (Component) => {
    function ComponentWithRouter(props) {
        useEffect( () => {
            props.loadMetatag();
            props.loadLegalServices();
        }, []);

        return (
            <Component />
        )
    }

    return ComponentWithRouter
}

const mapStateToProps = (state) => {
    return {
        legalService: state.legalService.data,
        legalServiceLoaded: state.legalService.data.length
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadMetatag: () => dispatch(loadMetatag()),
        loadLegalServices: () => dispatch(loadLegalServices())
    }
}; 

class NavigationBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPath: window.location.pathname,
            navigationRef: createRef(),
            services: {
                alacarte: [
                    'Konsultasi Hukum',
                    'Review Dokumen',
                    'Pembuatan Perjanjian',
                    'Pembuatan Perjanjian Kerahasiaan',
                    'Pembuatan SOP / Dokumen Lainnya',
                    'Pembuatan Somasi & Jawaban Somasi',
                    'Uji Tuntas Hukum',
                    'Template Kontrak'
                ],
                legalentity: [
                    'Pembuatan Badan Usaha',
                    'Pendaftaran Merek',
                ],
                package: [
                    'Landack Deposit',
                    'Landack Legal Boost',
                    'Landack Full Service'
                ]
            }
        };
    }

    componentWillMount() {

    }

    componentDidMount() {
        // const thisState = this.state;
        // // this.handleNavClick(this.state.currentPath);
        // console.log(thisState.services.isLoaded);
        // if(!thisState.services.isLoaded) {
        //     thisState.services.isLoaded = true;
        //     // thisState.services.isLoaded = this.props.servicesLoaded;
        //     thisState.services.data = this.props.legalService;
            
        //     this.setState({
        //         ...thisState
        //     });
        // }
    }

    componentDidUpdate() {
        // const thisState = this.state;
        // // this.handleNavClick(this.state.currentPath);
        // console.log(thisState.services.isLoaded);
        // if(!thisState.services.isLoaded) {
        //     thisState.services.isLoaded = true;
        //     // thisState.services.isLoaded = this.props.servicesLoaded;
        //     thisState.services.data = this.props.legalService;
            
        //     this.setState({
        //         ...thisState
        //     });
        // }
    }

    getCurrentPath(path) {
        this.handleNavClick(path);
    }

    handleNavClick = (path) => {
        let navRef = this.state.navRef.current;
        
        if(navRef !== null) {
            const currentPath = path;
            const elementId = currentPath.replace('#', '');
    
            // const navbar = document.getElementById('navigation-bar');
            const section = document.getElementById(elementId);
            
            window.scroll({
                behavior: 'smooth',
                top: section.offsetTop
                // top: section.offsetTop
            });
        }
    }

    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };

        window.removeEventListener('resize', () => {});
    }

    toggleDropdown(id, show) {
        const dropdown = document.getElementById(`navbar-hover-${id}`);

        console.log("Toggle Dropdown!");

        if(dropdown !== null) {
            show ? dropdown.classList.add('show') : dropdown.classList.remove('show');
        }
    }

    render() {
        let state = this.state;
        let logoPath = Init.config.imageLogoAlt;
        let { navList, show } = this.props;
        // let navList = [];

        return (
            <Router>
                <Navbar ref={this.state.navRef} variant='dark' id="navigation-bar" expand="xl" className={`custom-navbar-bg ${show ? '' : 'd-none'}`}>
                    <Navbar.Brand as={Link} to="/">
                        <img className="nav-logo" src={logoPath} alt="Logo" />
                    </Navbar.Brand>

                    <NavLink to={`/services`} 
                        // activeClassName="active" 
                        className="nav-link ml-2" 
                        onClick={() => this.getCurrentPath(`/services`)}
                        onMouseEnter={ () => this.toggleDropdown('service', true) }
                        onMouseLeave={ () => this.toggleDropdown('service', false) }> Layanan <FontAwesomeIcon icon={['fas', 'caret-down']} /> </NavLink>
                    <div 
                        id={ `navbar-hover-service` } 
                        className="navbar-hover"
                        onMouseEnter={ () => this.toggleDropdown('service', true) }
                        onMouseLeave={ () => this.toggleDropdown('service', false) }
                    >
                        <div className="dropdown-container">
                            <h5 className="dropdown-name">
                                Layanan
                            </h5>    
                            <div className="dropdown-items">
                                {
                                    this.state.services.alacarte.map((v, k) => {
                                        return (
                                            <Link 
                                                key={k}
                                                className='dropdown-item'
                                                to={`#`}
                                            >
                                                { v }
                                            </Link>
                                        )
                                    })
                                }       
                            </div>
                            <br />
                            <h5 className="dropdown-name">
                                Badan Usaha
                            </h5>    
                            <div className="dropdown-items">
                                {
                                    this.state.services.legalentity.map((v, k) => {
                                        return (
                                            <Link 
                                                key={k}
                                                className='dropdown-item'
                                                to={`#`}
                                            >
                                                { v }
                                            </Link>
                                        )
                                    })
                                }       
                            </div>
                            <br />
                            <h5 className="dropdown-name">
                                Paket
                            </h5>    
                            <div className="dropdown-items">
                                {
                                    this.state.services.package.map((v, k) => {
                                        return (
                                            <Link 
                                                key={k}
                                                className='dropdown-item highlighted'
                                                to={`#`}
                                            >
                                                { v }
                                            </Link>
                                        )
                                    })
                                }       
                            </div>
                        </div>
                    </div>
                    
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                    </Navbar.Toggle>

                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className="">
                            {
                                navList.map( (item, index) => {
                                    let navLinks = "";

                                    if(item.hasOwnProperty('visibility') && item.visibility) {
                                        navLinks = (
                                            <NavLink key={index} 
                                                    to={item.path} 
                                                    // activeClassName="active" 
                                                    className="nav-link ml-2" 
                                                    onClick={() => this.getCurrentPath(item.path)}> {item.name} </NavLink>
                                        );
                                    }

                                    return navLinks;
                                } )
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                {/* Render the first matched path */}
                <Routes>
                    {
                        this.props.navList.map((route, i) => {
                                const ComponentWithParameters = withParameters(route.component);

                                return (
                                    <Route
                                        key={i}
                                        path={route.path}
                                        element={
                                            <ComponentWithParameters
                                                loadMetatag={ () => this.props.loadMetatag() }
                                                loadLegalServices={ () => this.props.loadLegalServices() }
                                            />
                                        }
                                    />
                                )
                            } 
                        )
                    }
                </Routes>
            </Router>
            // <Fragment>
            // </Fragment>
        );
    }
}

export default connect(null, mapDispatchToProps)(NavigationBar);