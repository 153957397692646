import React, { useContext, useState, Fragment } from 'react';
import Anchor from '../../Utilities/Anchor/Anchor';

import Init from '../../../Init/Init';
import './Advantages.scss';
import ContactInfo from '../../../Data/Contact';

const AdvantageList = (props) => {
    const { advantages } = props;

    return (
        <Fragment>
            <div className="advantage-header-container">
                <h2 className='advantage-title'>
                    Alasan untuk memilih Landack:  
                </h2>
            </div>
            <div className="advantage-grid-section">
                {
                    advantages.map( (v, k) => {
                        return (
                            <div key={k} className={"advantage-item" + (k % 3 > 0 ? " advantage-item-reverse" : "")}>
                                <div className="advantage-item-illustration">
                                    <img src={v.icon} alt="Illustrasi" />
                                </div>
                                <div className="advantage-item-content">
                                    <h3 className="advantage-item-title">
                                        { v.name }
                                    </h3>
                                    <p className="advantage-item-desc" dangerouslySetInnerHTML={{__html: v.description}}>
                                        
                                    </p>
                                </div>
                            </div>
                        );
                    } )
                }
            </div>
        </Fragment>
    );
}

const Advantages = (props) => {
    const { whyUs } = Init.config.sectionAssets;
    const info = ContactInfo();
    const prpLink = info.prpLink;

    const [ advantages, setAdvantages ] = useState([
        {
            name: 'Mudah',
            illustration: 'mobile-phone-bokeh-1.png',
            icon: whyUs.simple,
            description: `Konsultasi & jasa hukum semudah 1 + 1, TANPA PUSING`
        },
        {
            name: 'Biaya jelas dan merakyat',
            illustration: 'mobile-phone-bokeh-1.png',
            icon: whyUs.affordable,
            description: `Bebas dari biaya mahal dan biaya-biaya terselubung. Akses hukum untuk semua!`
        },
        {
            name: 'Respon Cepat',
            illustration: 'mobile-phone-bokeh-1.png',
            icon: whyUs.fast,
            description: `Landack. beda dengan dia yang chat kamu cuma di read aja, Landack. balesnya cepat dan sigap!`
        },
        {
            name: 'Akurat & Tepat',
            illustration: 'mobile-phone-bokeh-1.png',
            icon: whyUs.accurate,
            description: `Jangan salah pilih, Landack. siap memberikan saran yang terbaik untuk masalahmu`
        },
        {
            name: 'Didukung oleh Kantor Hukum bersertifikasi',
            illustration: 'prp.svg',
            icon: whyUs.prp,
            description: `Landack. didukung oleh Pribadi Randa & Partners Law Firm sebagai kantor hukum bersertifikasi. Silahkan cek websitenya dengan <a target="_blank" href="${prpLink}">klik disini</a>.`
        },
        {
            name: 'Profesional',
            icon: whyUs.professional,
            description: `Tak perlu khawatir, Landack. bisa menjamin keamanan data sobat!`
        },
    ]);

    return (
        <div id="advantage" className="advantages">
            <AdvantageList advantages={advantages} />
        </div>
    );
}



export default Advantages;